import { AuthenticationContextProvider } from "./authentication-context";
interface StoreProps {
  readonly children: React.ReactNode;
}

function Store({ children }: StoreProps) {
  return (
    <AuthenticationContextProvider>{children}</AuthenticationContextProvider>
  );
}

export default Store;
