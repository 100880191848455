import { AxiosResponse } from "axios";
import { createContext, useCallback, useMemo } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { postData } from "../api/base";

const AuthenticationContext = createContext<{
  isAuthenticated: boolean;
  logOut: () => void;
  signIn: (res: AxiosResponse) => void;
}>({
  isAuthenticated: false,
  logOut: () => {},
  signIn: (res: AxiosResponse) => {},
});

interface AuthenticationContextProviderProps {
  readonly children: React.ReactNode;
}

export function AuthenticationContextProvider({
  children,
}: AuthenticationContextProviderProps) {
  const accessToken = localStorage.getItem("accessToken");
  let isAuthenticated = useMemo(() => {
    return accessToken ? true : false;
  }, [accessToken]);
  const navigate = useNavigate();
  const { mutate } = useMutation(() => postData("logout"));
  const signIn = useCallback(
    (res: AxiosResponse) => {
      localStorage.setItem("accessToken", res?.data?.access_token);
      navigate("/dashboard");
    },
    [navigate]
  );

  const logOut = useCallback(() => {
    localStorage.removeItem("accessToken");
    mutate();
    navigate("/login", {
      replace: true,
    });
  }, [navigate, mutate]);

  const context = { isAuthenticated, logOut, signIn };

  return (
    <AuthenticationContext.Provider value={context}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export default AuthenticationContext;
