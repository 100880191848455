import React from 'react';

function FooterLogo() {
  return (
    <svg width="240" height="47" viewBox="0 0 240 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.8269 41.9078L27.4508 46.9415H0V0H41.0299L37.401 5.03362H5.09215V41.9078H22.8269Z" fill="#3434FF"/>
      <path
        d="M10.594 36.1721V10.126H15.5691V27.3925L28.4458 10.126H34.533L23.2366 25.3439L41.9078 47.0002H35.1768L20.193 29.441L15.5691 36.1721H10.594Z"
        fill="#3434FF"/>
      <path
        d="M68.914 18.9524H63.9974C63.3185 16.0493 61.5274 14.5978 58.6243 14.5978C56.447 14.5978 54.773 15.464 53.6024 17.1965C52.5957 18.7183 52.0923 20.7435 52.0923 23.272C52.0923 26.1283 52.7245 28.3056 53.9887 29.804C55.0891 31.0917 56.5758 31.7355 58.4487 31.7355C60.0642 31.7355 61.3519 31.3141 62.3118 30.4712C63.2717 29.6284 63.8335 28.4227 63.9974 26.854H69.0194C68.8555 29.8976 67.6849 32.2506 65.5075 33.9128C63.6345 35.3644 61.2348 36.0902 58.3083 36.0902C54.5623 36.0902 51.6592 34.7791 49.5989 32.1569C47.8196 29.8859 46.9299 26.8892 46.9299 23.1666C46.9299 18.9056 48.1005 15.6045 50.4418 13.2633C52.4786 11.203 55.1359 10.1729 58.4136 10.1729C62.3 10.1729 65.2383 11.4722 67.2283 14.071C68.2116 15.3587 68.7735 16.9858 68.914 18.9524Z"
        fill="white"/>
      <path
        d="M80.7132 16.7049C83.7802 16.7049 86.1331 17.6882 87.772 19.6548C89.2001 21.3405 89.9142 23.588 89.9142 26.3975C89.9142 29.6284 88.9894 32.1101 87.1398 33.8426C85.5244 35.341 83.3822 36.0902 80.7132 36.0902C77.6462 36.0902 75.2933 35.1186 73.6544 33.1753C72.2263 31.4663 71.5122 29.207 71.5122 26.3975C71.5122 23.19 72.437 20.7201 74.2865 18.9875C75.902 17.4658 78.0442 16.7049 80.7132 16.7049ZM80.7132 20.603C79.4021 20.603 78.3486 21.1415 77.5525 22.2184C76.7565 23.272 76.3585 24.665 76.3585 26.3975C76.3585 28.2705 76.8034 29.7455 77.693 30.8224C78.489 31.7355 79.4958 32.192 80.7132 32.192C82.0243 32.192 83.0778 31.6653 83.8738 30.6117C84.6933 29.5347 85.103 28.13 85.103 26.3975C85.103 24.5479 84.6464 23.0847 83.7334 22.0077C82.9374 21.0712 81.9306 20.603 80.7132 20.603Z"
        fill="white"/>
      <path
        d="M110.13 10.5592V35.7039H105.284V33.6319C104.16 35.2707 102.463 36.0902 100.192 36.0902C97.7335 36.0902 95.7552 35.06 94.2568 32.9998C92.9691 31.2438 92.3253 29.0548 92.3253 26.4326C92.3253 23.3188 93.1447 20.8605 94.7836 19.0578C96.2117 17.4892 98.0027 16.7049 100.157 16.7049C102.428 16.7049 104.137 17.5711 105.284 19.3036V10.5592H110.13ZM101.245 20.6732C100.028 20.6732 99.0446 21.2 98.2954 22.2536C97.5462 23.3071 97.1716 24.6884 97.1716 26.3975C97.1716 28.0832 97.5345 29.4528 98.2603 30.5064C99.0095 31.5365 100.004 32.0516 101.245 32.0516C102.486 32.0516 103.469 31.5365 104.195 30.5064C104.944 29.4762 105.319 28.1066 105.319 26.3975C105.319 24.665 104.944 23.2837 104.195 22.2536C103.446 21.2 102.463 20.6732 101.245 20.6732Z"
        fill="white"/>
      <path
        d="M130.868 27.6618H118.366C118.436 29.1602 118.67 30.2254 119.068 30.8575C119.747 31.8877 120.813 32.4027 122.264 32.4027C124.067 32.4027 125.273 31.6536 125.881 30.1552H130.657C130.119 31.9813 129.065 33.4329 127.497 34.5098C125.952 35.5634 124.137 36.0902 122.053 36.0902C119.221 36.0902 117.043 35.142 115.521 33.2456C114.187 31.5833 113.52 29.3943 113.52 26.6785C113.52 23.3773 114.386 20.8254 116.118 19.0227C117.64 17.4775 119.642 16.7049 122.124 16.7049C124.02 16.7049 125.682 17.1731 127.111 18.1096C128.539 19.0461 129.557 20.3572 130.166 22.0428C130.657 23.3539 130.903 24.9342 130.903 26.7838C130.903 27.0179 130.892 27.3106 130.868 27.6618ZM125.846 24.466C125.612 21.7502 124.36 20.3923 122.089 20.3923C119.958 20.3923 118.741 21.7502 118.436 24.466H125.846Z"
        fill="white"/>
      <path
        d="M139.142 24.3255L145.217 17.0912H150.731L144.374 24.1148L151.152 35.7039H145.498L141.038 27.4862L139.142 29.5933V35.7039H134.295V10.5592H139.142V24.3255Z"
        fill="white"/>
      <path
        d="M169.512 34.4747V35.7039H164.245C163.893 35.259 163.706 34.6269 163.683 33.8075C162.395 35.3293 160.522 36.0902 158.064 36.0902C156.261 36.0902 154.809 35.54 153.709 34.4396C152.819 33.5499 152.375 32.3208 152.375 30.7522C152.375 28.411 153.311 26.7487 155.184 25.7654C155.98 25.344 157.56 24.9225 159.925 24.5011L163.718 23.7988L163.683 22.4643C163.659 21.2234 162.723 20.603 160.873 20.603C159.679 20.603 158.836 20.7903 158.345 21.1649C157.876 21.5161 157.584 22.1716 157.467 23.1315H152.831C153.112 18.8471 155.758 16.7049 160.768 16.7049C163.53 16.7049 165.521 17.1965 166.738 18.1798C167.838 19.1163 168.389 20.5328 168.389 22.4291V32.8242C168.389 33.667 168.763 34.2172 169.512 34.4747ZM163.718 26.7136L160.171 27.5213C159.164 27.7554 158.415 28.0949 157.923 28.5397C157.455 28.9846 157.221 29.5347 157.221 30.1903C157.221 31.5248 158.087 32.192 159.82 32.192C162.325 32.192 163.612 30.8224 163.683 28.0832L163.718 26.7136Z"
        fill="white"/>
      <path
        d="M177.586 17.0912V19.7602C178.71 17.7233 180.431 16.7049 182.748 16.7049C183.849 16.7049 184.926 16.9741 185.979 17.5126C187.056 18.0511 187.911 18.7534 188.543 19.6197C189.877 21.4692 190.545 23.7753 190.545 26.538C190.545 29.5113 189.713 31.9111 188.051 33.7372C186.623 35.3059 184.855 36.0902 182.748 36.0902C180.454 36.0902 178.733 35.2122 177.586 33.4563V43.2192H172.74V17.0912H177.586ZM181.625 20.7435C180.407 20.7435 179.424 21.2702 178.675 22.3238C177.925 23.3539 177.551 24.7235 177.551 26.4326C177.551 28.1183 177.925 29.4762 178.675 30.5064C179.424 31.5365 180.407 32.0516 181.625 32.0516C182.842 32.0516 183.825 31.5365 184.574 30.5064C185.324 29.4762 185.698 28.1183 185.698 26.4326C185.698 24.7235 185.324 23.3539 184.574 22.3238C183.825 21.2702 182.842 20.7435 181.625 20.7435Z"
        fill="white"/>
      <path d="M198.886 10.5592V35.7039H194.039V10.5592H198.886Z" fill="white"/>
      <path
        d="M219.48 34.4747V35.7039H214.213C213.862 35.259 213.674 34.6269 213.651 33.8075C212.363 35.3293 210.49 36.0902 208.032 36.0902C206.229 36.0902 204.778 35.54 203.677 34.4396C202.788 33.5499 202.343 32.3208 202.343 30.7522C202.343 28.411 203.279 26.7487 205.152 25.7654C205.948 25.344 207.529 24.9225 209.893 24.5011L213.686 23.7988L213.651 22.4643C213.627 21.2234 212.691 20.603 210.841 20.603C209.647 20.603 208.804 20.7903 208.313 21.1649C207.845 21.5161 207.552 22.1716 207.435 23.1315H202.799C203.08 18.8471 205.726 16.7049 210.736 16.7049C213.499 16.7049 215.489 17.1965 216.706 18.1798C217.806 19.1163 218.357 20.5328 218.357 22.4291V32.8242C218.357 33.667 218.731 34.2172 219.48 34.4747ZM213.686 26.7136L210.139 27.5213C209.132 27.7554 208.383 28.0949 207.891 28.5397C207.423 28.9846 207.189 29.5347 207.189 30.1903C207.189 31.5248 208.055 32.192 209.788 32.192C212.293 32.192 213.581 30.8224 213.651 28.0832L213.686 26.7136Z"
        fill="white"/>
      <path
        d="M222.743 17.0912H227.589V19.725C228.9 17.7116 230.75 16.7049 233.138 16.7049C235.268 16.7049 236.884 17.337 237.984 18.6012C238.921 19.725 239.389 21.2468 239.389 23.1666V35.7039H234.578V24.1499C234.578 21.9258 233.536 20.8137 231.452 20.8137C230.282 20.8137 229.345 21.1532 228.643 21.8321C227.94 22.4877 227.589 23.3656 227.589 24.466V35.7039H222.743V17.0912Z"
        fill="white"/>
    </svg>
  );
}

export default FooterLogo;