import React from 'react';

function WhiteLogo() {
  return (
    <svg width="561" height="111" viewBox="0 0 561 111" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M53.4941 98.2097L64.3301 110.006H0V0H96.1522L87.648 11.7961H11.9333V98.2097H53.4941Z" fill="white"/>
      <path
        d="M24.8262 84.7676V23.7294H36.4851V64.1929L66.6613 23.7294H80.9264L54.4537 59.3922L98.2091 110.143H82.4352L47.3211 68.9937L36.4851 84.7676H24.8262Z"
        fill="white"/>
      <path
        d="M161.497 44.4138H149.976C148.385 37.6104 144.187 34.2088 137.384 34.2088C132.281 34.2088 128.359 36.2388 125.615 40.2989C123.256 43.8651 122.076 48.611 122.076 54.5365C122.076 61.2302 123.558 66.3327 126.521 69.8441C129.099 72.8617 132.583 74.3705 136.972 74.3705C140.758 74.3705 143.776 73.3829 146.025 71.4078C148.275 69.4326 149.592 66.607 149.976 62.931H161.744C161.36 70.0635 158.617 75.5776 153.514 79.473C149.125 82.8747 143.501 84.5755 136.643 84.5755C127.865 84.5755 121.061 81.503 116.233 75.3581C112.063 70.0361 109.979 63.0133 109.979 54.2896C109.979 44.3041 112.722 36.568 118.208 31.0814C122.982 26.2532 129.209 23.8391 136.89 23.8391C145.998 23.8391 152.884 26.8842 157.547 32.9743C159.851 35.9919 161.168 39.8051 161.497 44.4138Z"
        fill="white"/>
      <path
        d="M189.148 39.1467C196.336 39.1467 201.85 41.4511 205.691 46.0598C209.037 50.0101 210.711 55.2772 210.711 61.8611C210.711 69.4326 208.544 75.2484 204.209 79.3084C200.423 82.8198 195.403 84.5755 189.148 84.5755C181.961 84.5755 176.447 82.2986 172.606 77.7447C169.26 73.7395 167.586 68.445 167.586 61.8611C167.586 54.3445 169.753 48.5562 174.088 44.4961C177.874 40.9298 182.894 39.1467 189.148 39.1467ZM189.148 48.2818C186.076 48.2818 183.607 49.5438 181.742 52.0676C179.876 54.5365 178.943 57.801 178.943 61.8611C178.943 66.2504 179.986 69.7069 182.071 72.2307C183.936 74.3705 186.295 75.4404 189.148 75.4404C192.221 75.4404 194.69 74.2059 196.555 71.7369C198.476 69.2131 199.436 65.9212 199.436 61.8611C199.436 57.5267 198.366 54.0976 196.226 51.5738C194.361 49.3792 192.002 48.2818 189.148 48.2818Z"
        fill="white"/>
      <path
        d="M258.086 24.7444V83.6702H246.729V78.8146C244.096 82.6552 240.118 84.5755 234.796 84.5755C229.035 84.5755 224.399 82.1614 220.887 77.3332C217.87 73.2183 216.361 68.0884 216.361 61.9434C216.361 54.6463 218.281 48.8854 222.122 44.6607C225.469 40.9847 229.666 39.1467 234.714 39.1467C240.036 39.1467 244.041 41.1767 246.729 45.2368V24.7444H258.086ZM237.265 48.4464C234.412 48.4464 232.108 49.6809 230.352 52.1499C228.596 54.6188 227.718 57.8559 227.718 61.8611C227.718 65.8114 228.569 69.0211 230.27 71.4901C232.025 73.9041 234.357 75.1112 237.265 75.1112C240.173 75.1112 242.477 73.9041 244.178 71.4901C245.934 69.076 246.812 65.8663 246.812 61.8611C246.812 57.801 245.934 54.564 244.178 52.1499C242.422 49.6809 240.118 48.4464 237.265 48.4464Z"
        fill="white"/>
      <path
        d="M306.685 64.8239H277.387C277.551 68.3353 278.1 70.8317 279.033 72.313C280.624 74.7271 283.12 75.9342 286.522 75.9342C290.747 75.9342 293.572 74.1785 294.999 70.6671H306.191C304.929 74.9466 302.46 78.3483 298.784 80.8721C295.163 83.341 290.911 84.5755 286.028 84.5755C279.389 84.5755 274.287 82.3535 270.721 77.9093C267.593 74.0139 266.03 68.8839 266.03 62.5195C266.03 54.7834 268.06 48.8031 272.12 44.5784C275.686 40.9573 280.377 39.1467 286.193 39.1467C290.637 39.1467 294.532 40.244 297.879 42.4386C301.226 44.6333 303.613 47.7057 305.039 51.6561C306.191 54.7286 306.767 58.432 306.767 62.7664C306.767 63.3151 306.74 64.0009 306.685 64.8239ZM294.916 57.3347C294.368 50.9703 291.432 47.788 286.11 47.788C281.118 47.788 278.265 50.9703 277.551 57.3347H294.916Z"
        fill="white"/>
      <path
        d="M326.074 57.0055L340.312 40.052H353.233L338.337 56.5117L354.22 83.6702H340.97L330.518 64.4124L326.074 69.3503V83.6702H314.717V24.7444H326.074V57.0055Z"
        fill="white"/>
      <path
        d="M397.246 80.7898V83.6702H384.902C384.079 82.6278 383.64 81.1464 383.585 79.2261C380.567 82.7924 376.178 84.5755 370.417 84.5755C366.192 84.5755 362.791 83.2862 360.212 80.7075C358.127 78.6226 357.085 75.7421 357.085 72.0661C357.085 66.5796 359.279 62.6841 363.669 60.3797C365.534 59.3922 369.237 58.4046 374.779 57.417L383.667 55.771L383.585 52.6437C383.53 49.7358 381.335 48.2818 377.001 48.2818C374.203 48.2818 372.228 48.7208 371.075 49.5986C369.978 50.4216 369.292 51.9578 369.018 54.2073H358.155C358.813 44.1669 365.013 39.1467 376.754 39.1467C383.228 39.1467 387.892 40.2989 390.745 42.6032C393.323 44.7979 394.613 48.1172 394.613 52.5614V76.9218C394.613 78.8969 395.491 80.1863 397.246 80.7898ZM383.667 62.6018L375.355 64.4947C372.996 65.0433 371.24 65.8389 370.088 66.8813C368.99 67.9238 368.442 69.2131 368.442 70.7494C368.442 73.8767 370.472 75.4404 374.532 75.4404C380.403 75.4404 383.42 72.2307 383.585 65.8114L383.667 62.6018Z"
        fill="white"/>
      <path
        d="M416.167 40.052V46.3067C418.8 41.5333 422.833 39.1467 428.265 39.1467C430.843 39.1467 433.367 39.7776 435.836 41.0396C438.36 42.3015 440.362 43.9474 441.844 45.9775C444.971 50.3119 446.535 55.7161 446.535 62.1903C446.535 69.1583 444.587 74.782 440.692 79.0615C437.345 82.7375 433.203 84.5755 428.265 84.5755C422.888 84.5755 418.855 82.5181 416.167 78.4031V101.282H404.809V40.052H416.167ZM425.631 48.611C422.778 48.611 420.474 49.8455 418.718 52.3145C416.962 54.7286 416.084 57.9382 416.084 61.9434C416.084 65.8937 416.962 69.076 418.718 71.4901C420.474 73.9041 422.778 75.1112 425.631 75.1112C428.484 75.1112 430.788 73.9041 432.544 71.4901C434.3 69.076 435.178 65.8937 435.178 61.9434C435.178 57.9382 434.3 54.7286 432.544 52.3145C430.788 49.8455 428.484 48.611 425.631 48.611Z"
        fill="white"/>
      <path d="M466.082 24.7444V83.6702H454.725V24.7444H466.082Z" fill="white"/>
      <path
        d="M514.345 80.7898V83.6702H502C501.177 82.6278 500.738 81.1464 500.683 79.2261C497.666 82.7924 493.277 84.5755 487.516 84.5755C483.291 84.5755 479.889 83.2862 477.311 80.7075C475.226 78.6226 474.183 75.7421 474.183 72.0661C474.183 66.5796 476.378 62.6841 480.767 60.3797C482.633 59.3922 486.336 58.4046 491.878 57.417L500.766 55.771L500.683 52.6437C500.629 49.7358 498.434 48.2818 494.1 48.2818C491.301 48.2818 489.326 48.7208 488.174 49.5986C487.077 50.4216 486.391 51.9578 486.117 54.2073H475.253C475.912 44.1669 482.111 39.1467 493.853 39.1467C500.327 39.1467 504.99 40.2989 507.844 42.6032C510.422 44.7979 511.712 48.1172 511.712 52.5614V76.9218C511.712 78.8969 512.589 80.1863 514.345 80.7898ZM500.766 62.6018L492.454 64.4947C490.094 65.0433 488.339 65.8389 487.187 66.8813C486.089 67.9238 485.541 69.2131 485.541 70.7494C485.541 73.8767 487.571 75.4404 491.631 75.4404C497.501 75.4404 500.519 72.2307 500.683 65.8114L500.766 62.6018Z"
        fill="white"/>
      <path
        d="M521.99 40.052H533.348V46.2244C536.42 41.5059 540.755 39.1467 546.351 39.1467C551.344 39.1467 555.129 40.6281 557.708 43.5908C559.903 46.2244 561 49.7906 561 54.2896V83.6702H549.725V56.594C549.725 51.3818 547.284 48.7756 542.401 48.7756C539.657 48.7756 537.463 49.5712 535.817 51.1623C534.171 52.6985 533.348 54.756 533.348 57.3347V83.6702H521.99V40.052Z"
        fill="white"/>
    </svg>

  );
}

export default WhiteLogo;