import React, { ReactEventHandler, useEffect } from "react";
import { Input, Popper, Typography, Paper, useMediaQuery } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router";
import useDebounce from "../../shared/debounce";
import { fetcher } from "src/api/base";
import SearchIcon from "@mui/icons-material/Search";
import * as yup from "yup";
import styles from "./search.module.scss";
import { createSearchParams } from "react-router-dom";

export default function Search() {
  const searchWindow = React.useRef(null);
  const navigate = useNavigate();
  const [searchWindowOpen, setSearchWindowOpen] = React.useState(false);
  const [foundCourses, setFoundCourses] = React.useState([]);
  const location = useLocation();
  const keyword = new URLSearchParams(location.search);
  

  const handleEventClick = (e: any) => {
    if (e.target.id === "search-content" || e.target.id === "search-input") {
      return;
    }
    setSearchWindowOpen(false);
  };

  React.useEffect(() => {
    document.addEventListener("click", handleEventClick);
    return () => {
      document.removeEventListener("click", handleEventClick);
    };
  }, [searchWindowOpen]);

  const handleSearch = async (data: any) => {
    if (!data) {
      setSearchWindowOpen(false);
      return;
    }

    fetcher("/course/by-keyword/", {
      params: {
        search: data,
      },
    }).then((data) => {
      if (data?.data?.length) {
        setSearchWindowOpen(true);
        setFoundCourses(data?.data);
      } else {
        setSearchWindowOpen(false);
      }
    });
  };

  const debounce = useDebounce(handleSearch, 300);

  const schema = yup.object().shape({
    search: yup.string(),
  });

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (data: any) => {
    setSearchWindowOpen(false);
    navigate({
      pathname: "/courses/search",
      search: createSearchParams({
        key: data?.search,
      }).toString(),
    });
  };

  // const location = useLocation();
  // const keyword = new URLSearchParams(location?.search);

  useEffect(() => {
    setValue("search" , keyword.get("key") || '');
  }, [keyword]);

  const isMobile = useMediaQuery("(max-width:992px");
  const serverUrl = process.env.REACT_APP_API_URL;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`d-flex m-auto ${isMobile ? "" : "px-3"}`}
    >
      <Paper
        className={`d-flex m-auto align-items-center ${styles["search-input"]}`}
        style={{ width: "300px" }}
        ref={searchWindow}
      >
        <Controller
          name="search"
          control={control}
          render={({ field: { onChange, ...field } }) => (
            <Input
              placeholder="Search like to learn Next?"
              className="flex flex-1 mx-8"
              disableUnderline
              fullWidth
              onChange={(e) => {
                onChange(e);
                debounce(e.target.value);
              }}
              inputProps={{
                "aria-label": "Search",
              }}
              id="search-input"
              {...field}
            />
          )}
        />
        <SearchIcon color="primary" />
        {/* @ts-ignore */}
        <Popper
          placement="bottom-start"
          anchorEl={searchWindow.current}
          open={searchWindowOpen}
        >
          <div id="search-content" className={styles["search-content"]}>
            {searchWindowOpen &&
              foundCourses.map((f: any, i) => {
                return (
                  <div
                    onClick={() => {
                      // searchRef.current!.value = undefined;
                      setSearchWindowOpen(false);
                      navigate(`/courses/${f?._id}`);
                    }}
                    className={styles["search-item"]}
                  >
                    <img
                      className={styles["search-item-image"]}
                      src={serverUrl + "files/" + f?.imageUrl?.src}
                    />
                    <div>
                      <Typography variant="h6">{f?.name}</Typography>
                      <Typography
                        className={styles["search-item-description"]}
                        variant="body2"
                      >
                        {f?.description}
                      </Typography>
                    </div>
                  </div>
                );
              })}
          </div>
        </Popper>
      </Paper>
    </form>
  );
}
