import React from 'react';

function UserIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="#001AFF" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_122_499)">
        <path
          d="M9 8.99996C9.89002 8.99996 10.76 8.73604 11.5001 8.24157C12.2401 7.7471 12.8169 7.0443 13.1575 6.22203C13.4981 5.39976 13.5872 4.49496 13.4135 3.62205C13.2399 2.74914 12.8113 1.94731 12.182 1.31798C11.5526 0.688639 10.7508 0.260056 9.87791 0.0864225C9.00499 -0.087211 8.10019 0.00190401 7.27792 0.342498C6.45566 0.683093 5.75285 1.25987 5.25839 1.99989C4.76392 2.73991 4.5 3.60994 4.5 4.49996C4.50119 5.69306 4.97568 6.83697 5.81934 7.68062C6.66299 8.52428 7.80689 8.99877 9 8.99996ZM9 1.49996C9.59334 1.49996 10.1734 1.6759 10.6667 2.00555C11.1601 2.33519 11.5446 2.80373 11.7716 3.35191C11.9987 3.90008 12.0581 4.50328 11.9424 5.08523C11.8266 5.66717 11.5409 6.20172 11.1213 6.62128C10.7018 7.04083 10.1672 7.32656 9.58527 7.44231C9.00333 7.55807 8.40013 7.49866 7.85195 7.2716C7.30377 7.04453 6.83524 6.66001 6.50559 6.16667C6.17595 5.67332 6 5.0933 6 4.49996C6 3.70431 6.31607 2.94125 6.87868 2.37864C7.44129 1.81603 8.20435 1.49996 9 1.49996V1.49996Z"
        />
        <path
          d="M9 10.5005C7.2104 10.5024 5.49466 11.2142 4.22922 12.4797C2.96378 13.7451 2.25199 15.4609 2.25 17.2505C2.25 17.4494 2.32902 17.6401 2.46967 17.7808C2.61032 17.9214 2.80109 18.0005 3 18.0005C3.19891 18.0005 3.38968 17.9214 3.53033 17.7808C3.67098 17.6401 3.75 17.4494 3.75 17.2505C3.75 15.8581 4.30312 14.5227 5.28769 13.5381C6.27226 12.5536 7.60761 12.0005 9 12.0005C10.3924 12.0005 11.7277 12.5536 12.7123 13.5381C13.6969 14.5227 14.25 15.8581 14.25 17.2505C14.25 17.4494 14.329 17.6401 14.4697 17.7808C14.6103 17.9214 14.8011 18.0005 15 18.0005C15.1989 18.0005 15.3897 17.9214 15.5303 17.7808C15.671 17.6401 15.75 17.4494 15.75 17.2505C15.748 15.4609 15.0362 13.7451 13.7708 12.4797C12.5053 11.2142 10.7896 10.5024 9 10.5005V10.5005Z"/>
      </g>
      <defs>
        <clipPath id="clip0_122_499">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
}

export default UserIcon;