import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

const onRequest = (request: AxiosRequestConfig): AxiosRequestConfig => {
  request.headers = {
    ...request.headers,
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  return request;
};

const onRequestError = (error: AxiosError) => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  // axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

const axiosApi = setupInterceptorsTo(
  axios.create({
    baseURL: BASE_URL,
  })
);

export async function fetcher(url: string | any, config?: AxiosRequestConfig) {
  const response = await axiosApi(url, {
    ...config,
  });
  return response;
}

export async function postData(
  url: string,
  data?: {},
  config?: AxiosRequestConfig
) {
  const response = await axiosApi.post(url, data, {
    ...config,
  });
  return response;
}

export async function patchData(
  url: string,
  data: {},
  config?: AxiosRequestConfig
) {
  const response = await axiosApi.patch(BASE_URL + url, data, {
    ...config,
  });
  return response;
}

export async function putData(
  url: string,
  data: {},
  config?: AxiosRequestConfig
) {
  const response = await axiosApi.put(BASE_URL + url, data, {
    ...config,
  });
  return response;
}

export async function deleteData(url: string, config?: AxiosRequestConfig) {
  const response = await axiosApi.delete(BASE_URL + url, {
    ...config,
  });
  return response;
}


export default axiosApi;