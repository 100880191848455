import React from 'react';

function TwitterIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#122052" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.3676 6.37727C21.6009 6.71805 20.7758 6.94792 19.9101 7.05079C20.794 6.52162 21.4718 5.68342 21.7914 4.68351C20.9647 5.17416 20.0494 5.53018 19.0745 5.72237C18.2943 4.89095 17.1822 4.37109 15.9515 4.37109C13.5889 4.37109 11.6733 6.28667 11.6733 8.64972C11.6733 8.98457 11.711 9.31096 11.7843 9.62465C8.22827 9.44601 5.07528 7.74294 2.96497 5.15426C2.59667 5.78587 2.38585 6.52078 2.38585 7.30564C2.38585 8.78984 3.14108 10.0996 4.28916 10.8667C3.58812 10.8443 2.92814 10.6517 2.35114 10.3312C2.35071 10.349 2.35071 10.3672 2.35071 10.3854C2.35071 12.458 3.82561 14.1869 5.7831 14.5806C5.42411 14.678 5.04607 14.7305 4.65576 14.7305C4.37975 14.7305 4.11178 14.7038 3.85058 14.6534C4.39541 16.3531 5.97529 17.5905 7.84727 17.6248C6.38296 18.7724 4.5385 19.4566 2.53359 19.4566C2.18858 19.4566 1.8478 19.4362 1.51294 19.3964C3.40693 20.611 5.65567 21.3188 8.07163 21.3188C15.9418 21.3188 20.245 14.7995 20.245 9.14544C20.245 8.96002 20.2412 8.77502 20.2331 8.5913C21.0688 7.98932 21.7944 7.23536 22.3676 6.37727Z"
      />
    </svg>
  );
}

export default TwitterIcon;