import React from "react";
import { Link } from "react-router-dom";
import UserIcon from "../../assets/icons/user-icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

function NavigationButtons() {
  return (
    <div className="header__buttons">
      <a
        href="https://platform.codekaplan.com/signup"
        className="button-filled"
      >
        <FontAwesomeIcon icon={faPenToSquare} size="lg" /> Sign up
      </a>
      <a href="https://platform.codekaplan.com/login" className="button">
        <UserIcon /> Log in
      </a>
    </div>
  );
}

export default NavigationButtons;
