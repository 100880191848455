import debounce from "lodash/debounce";


function useDebounce(
  func: (data: any) => Promise<void>,
  wait: number,
  options?: any
) {
  return debounce(func, wait, options);
}

export default useDebounce;
