import React from "react";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { fetcher } from "../../api/base/index";
import "./navigation.scss";

interface NavigationNavProps {
  readonly setIsMenuOpen: (isOpen: boolean) => void;
}

function NavigationNav({ setIsMenuOpen }: NavigationNavProps) {
  const { data, isLoading } = useQuery("categories", () => fetcher("category"));

  return (
    <ul id="nav-list">
      {/* <li onClick={() => setIsMenuOpen(false)}><NavLink to="/tutorial">Free Tutorials</NavLink></li> */}
      <li className="nav-course" onClick={() => setIsMenuOpen(false)}>
        <NavLink style={{ whiteSpace: "nowrap" }} to="/courses">
          Courses &#9660;
        </NavLink>
      </li>
      <div className="nav-categories">
        {data?.data?.map((d: any, i: number) => {
          if (i >= 9) {
            return;
          }
          return (
            //@ts-ignore
            <NavLink to={`/courses/filter/${d?._id}`} className="link">
              {d?.name}
            </NavLink>
          );
        })}
        <NavLink className="link" to="/courses">
          All courses
        </NavLink>
      </div>
      <li className="nav-resource" onClick={() => setIsMenuOpen(false)}>
        <NavLink style={{ whiteSpace: "nowrap" }} to="/courses">
          Resources &#9660;
        </NavLink>
      </li>

      <ul className="nav-resource-items">
        {/* <NavLink onClick={() => setIsMenuOpen(false)} to="/practice">
          Practice
        </NavLink> */}
        <NavLink to="/blog">Blog</NavLink>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/contact">Contact</NavLink>
      </ul>
      <li onClick={() => setIsMenuOpen(false)}>
        <NavLink style={{ whiteSpace: "nowrap" }} to="/becomeateacher">
          Become a teacher
        </NavLink>
      </li>
    </ul>
  );
}

export default NavigationNav;
