import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router";
import Spinner from "../spinner/spinner";
import AuthLayout from "../auth-layout/auth-layout";
import Layout from "../layout";
import Teacherapplication from "../../pages/teacher-application/teacherapplication";

const SearchPage = lazy(() => import("../../pages/search-page"));
const HomePage = lazy(() => import("../../pages/home/home"));
const AboutPage = lazy(() => import("../../pages/about/about"));
const CoursesPage = lazy(() => import("../../pages/courses"));

const CourseDetail = lazy(() => import("../../pages/course-detail"));
const PracticePage = lazy(() => import("../../pages/practice/practice"));
const BlogPage = lazy(() => import("../../pages/blog/blog"));
const BlogDetailPage = lazy(
  () => import("../../pages/blog-detail/blog-detail")
);
const ContactPage = lazy(() => import("../../pages/contact/contact"));
const LoginPage = lazy(() => import("../../pages/login/login"));
const SignUpPage = lazy(() => import("../../pages/signUp/signUp"));
const ResetPassword = lazy(
  () => import("../../pages/reset-password/reset-password")
);
const ForgotPassword = lazy(
  () => import("../../pages/forgot-password/forgot-password")
);
const TutorialPage = lazy(() => import("../../pages/tutorial/tutorial"));
const Dashboard = lazy(() => import("../../pages/dashboard/dashboard"));
const ChallengesPage = lazy(() => import("../../pages/challenges/challenges"));
const VerifyReset = lazy(() => import("../../pages/verify-email/verify-reset"));
const VerifySignup = lazy(
  () => import("../../pages/verify-email/verify-signup")
);
const VerifyEmailLink = lazy(
  () => import("../../pages/verify-email/verify-email-link")
);
const Success = lazy(() => import("../../pages/success"));
const Privacy = lazy(() => import("../../pages/privacy/privacy"));
const Terms = lazy(() => import("../../pages/terms/Terms"));
const Teachers = lazy(
  () => import("../../pages/become-a-teacher/becomeateacher")
);

function Routing() {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/courses/search" element={<SearchPage />} />
          <Route path="/courses" element={<CoursesPage />} />
          <Route path="/courses/filter/:id" element={<CoursesPage />} />
          <Route path="courses/:id" element={<CourseDetail />} />
          <Route path="/practice" element={<PracticePage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:id" element={<BlogDetailPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/tutorial" element={<TutorialPage />} />
          <Route path="/challenges" element={<ChallengesPage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/becomeateacher" element={<Teachers />} />
          <Route path="/application" element={<Teacherapplication />} />
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password/:id" element={<ResetPassword />} />
          <Route path="/verify-signup" element={<VerifySignup />} />
          <Route path="/verify-reset" element={<VerifyReset />} />
          <Route path="/success" element={<Success />} />
          <Route path="/verify-email/:id" element={<VerifyEmailLink />} />
        </Route>
        <Route element={<Dashboard />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default Routing;
