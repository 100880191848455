import React, { useState } from "react";
import CheckBox from "src/components/checkbox/Checkbox";
import "./teacherapplication.scss";

interface AppProps {}

interface ArrObject {
  id: number;
  value: string;
  isChecked: boolean;
}

interface AppState {
  services: ArrObject[];
}

class Teacherapplication extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      services: [
        { id: 1, value: "Create pre-recorded video lessons", isChecked: false },
        { id: 2, value: "Provide mentor support", isChecked: false },
        { id: 3, value: "Provide live one-on-one lessons", isChecked: false },
        { id: 4, value: "Provide live group sessions", isChecked: false },
        { id: 5, value: "Interview preparation support", isChecked: false },
        { id: 6, value: "Job Market preparations support", isChecked: false },
      ],
    };
  }

  handleCheckChildElement = (event: React.FormEvent<HTMLInputElement>) => {
    let services = this.state.services;
    services.forEach((service) => {
      if (service.value === event.currentTarget.value) {
        service.isChecked = event.currentTarget.checked;
      }
    });
    this.setState({ services: services });
  };

  render() {
    return (
      <>
        <div className="teacher-application">
          <div className="container">
            <form className="application-form">
              <div className="application-title">
                <h3>Application</h3>
                <p>
                  This form is to gather information from new trainers/mentors
                  regarding training and services they would like to provide.
                </p>
              </div>
              <div className="application-form">
                <div className="form-group">
                  <label htmlFor="first-name">First Name</label>
                  <input type="text" name="name" id="first-name" />
                </div>
                <div className="form-group">
                  <label htmlFor="last-name">Last Name</label>
                  <input type="text" name="" id="first-name" />
                </div>
                <div className="form-group">
                  <label htmlFor="last-name">Email address</label>
                  <input type="email" name="email" id="email" />
                </div>
                <div className="form-group">
                  <label htmlFor="last-name">Phone number</label>
                  <input type="phone" name="phone" id="phone" />
                </div>
                <div className="form-group">
                  <label htmlFor="experience">Total IT Experience</label>
                  <input type="text" />
                </div>
                <div className="form-group">
                  <label htmlFor="experience">
                    What kind of trainings and/or services can you provide?
                    <ul style={{ listStyleType: "none" }}>
                      {this.state.services.map((service) => {
                        return (
                          <CheckBox
                            handleCheckChildElement={
                              this.handleCheckChildElement
                            }
                            id={service.id}
                            value={service.value}
                            isChecked={service.isChecked}
                          />
                        );
                      })}
                    </ul>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="resume">Upload your resume</label>
                  <input type="file" id="resume" name="resume"/>
                </div>
                <div className="form-group">
                  <label htmlFor="resume">Cover letter</label>
                  <input type="text" id="cover" name="cover-letter"/>
                </div>
              </div>

              <div className="form-group form-check">
                <input
                  type="checkbox"
                  //  {...register('acceptTerms')}
                  //     className={`form-check-input ${ errors.acceptTerms ? 'is-invalid' : ''}`
                  // }
                />
                <label htmlFor="acceptTerms" className="form-check-label">
                  I have read and agree to the Terms
                </label>
                <div className="invalid-feedback">
                  {/* {errors.acceptTerms?.message} */}
                </div>
              </div>
              <div className="form-group">
              <button
                className="button-filled auth-layout__button"
                type="submit"
                >
                Submit
              </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Teacherapplication;
