import React from 'react';

function Logo() {
  return (
    <svg width="169" height="34" viewBox="0 0 169 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.115 29.5855L19.3793 33.139H0V0H28.9656L26.4038 3.55356H3.59488V29.5855H16.115Z" fill="#3434FF"/>
      <path
        d="M7.479 25.536V7.14844H10.9912V19.338L20.0817 7.14844H24.3791L16.4042 17.8918L29.5855 33.1803H24.8336L14.2556 20.7842L10.9912 25.536H7.479Z"
        fill="#3434FF"/>
      <path
        d="M48.6508 13.3796H45.1799C44.7006 11.3301 43.4362 10.3053 41.3867 10.3053C39.8496 10.3053 38.6678 10.9169 37.8414 12.1399C37.1307 13.2143 36.7753 14.644 36.7753 16.429C36.7753 18.4454 37.2216 19.9826 38.1141 21.0404C38.8909 21.9494 39.9405 22.4039 41.2627 22.4039C42.4032 22.4039 43.3122 22.1064 43.9899 21.5114C44.6675 20.9164 45.0642 20.0652 45.1799 18.9578H48.7252C48.6095 21.1065 47.7831 22.7676 46.246 23.9411C44.9237 24.9658 43.2296 25.4782 41.1636 25.4782C38.519 25.4782 36.4696 24.5526 35.0151 22.7015C33.7589 21.0982 33.1309 18.9826 33.1309 16.3546C33.1309 13.3465 33.9573 11.016 35.6101 9.36321C37.048 7.90873 38.924 7.18149 41.2379 7.18149C43.9816 7.18149 46.0559 8.0988 47.4608 9.93343C48.155 10.8425 48.5517 11.9912 48.6508 13.3796Z"
        fill="#0B0B2C"/>
      <path
        d="M56.9806 11.7929C59.1458 11.7929 60.8069 12.487 61.9639 13.8754C62.9721 15.0654 63.4762 16.6521 63.4762 18.6355C63.4762 20.9164 62.8234 22.6684 61.5176 23.8915C60.3772 24.9493 58.8649 25.4782 56.9806 25.4782C54.8155 25.4782 53.1544 24.7923 51.9974 23.4204C50.9892 22.2139 50.4851 20.6189 50.4851 18.6355C50.4851 16.3712 51.1379 14.6274 52.4437 13.4044C53.5841 12.33 55.0964 11.7929 56.9806 11.7929ZM56.9806 14.5448C56.0551 14.5448 55.3113 14.9249 54.7493 15.6852C54.1874 16.429 53.9064 17.4124 53.9064 18.6355C53.9064 19.9578 54.2204 20.9991 54.8485 21.7594C55.4105 22.404 56.1212 22.7263 56.9806 22.7263C57.9062 22.7263 58.65 22.3544 59.212 21.6106C59.7904 20.8503 60.0797 19.8586 60.0797 18.6355C60.0797 17.3298 59.7574 16.2968 59.1128 15.5365C58.5508 14.8754 57.8401 14.5448 56.9806 14.5448Z"
        fill="#0B0B2C"/>
      <path
        d="M77.7481 7.4542V25.2055H74.3267V23.7427C73.5334 24.8997 72.3351 25.4782 70.7318 25.4782C68.9964 25.4782 67.5998 24.751 66.5419 23.2965C65.6329 22.0569 65.1784 20.5115 65.1784 18.6603C65.1784 16.4621 65.7569 14.7266 66.9138 13.4539C67.9221 12.3465 69.1865 11.7929 70.7071 11.7929C72.3103 11.7929 73.5168 12.4044 74.3267 13.6275V7.4542H77.7481ZM71.4756 14.5944C70.6161 14.5944 69.922 14.9663 69.3931 15.71C68.8642 16.4538 68.5997 17.429 68.5997 18.6355C68.5997 19.8256 68.8559 20.7925 69.3683 21.5362C69.8972 22.2635 70.5996 22.6271 71.4756 22.6271C72.3516 22.6271 73.0458 22.2635 73.5582 21.5362C74.0871 20.809 74.3515 19.8421 74.3515 18.6355C74.3515 17.4124 74.0871 16.4373 73.5582 15.71C73.0293 14.9663 72.3351 14.5944 71.4756 14.5944Z"
        fill="#0B0B2C"/>
      <path
        d="M92.3883 19.528H83.5622C83.6118 20.5858 83.7771 21.3379 84.0581 21.7841C84.5374 22.5114 85.2894 22.875 86.3142 22.875C87.5868 22.875 88.438 22.3461 88.8678 21.2883H92.2395C91.8594 22.5775 91.1156 23.6022 90.0082 24.3625C88.9174 25.1063 87.6364 25.4782 86.1654 25.4782C84.1655 25.4782 82.6284 24.8088 81.5541 23.47C80.6119 22.2965 80.1409 20.7511 80.1409 18.8339C80.1409 16.5034 80.7524 14.7018 81.9755 13.4291C83.0499 12.3383 84.463 11.7929 86.215 11.7929C87.5538 11.7929 88.7273 12.1234 89.7355 12.7845C90.7437 13.4457 91.4627 14.3713 91.8924 15.5613C92.2395 16.4869 92.4131 17.6025 92.4131 18.9082C92.4131 19.0735 92.4048 19.2801 92.3883 19.528ZM88.843 17.2719C88.6777 15.3547 87.7934 14.396 86.1902 14.396C84.6861 14.396 83.8267 15.3547 83.6118 17.2719H88.843Z"
        fill="#0B0B2C"/>
      <path
        d="M98.2292 17.1728L102.518 12.0656H106.411L101.923 17.024L106.708 25.2055H102.717L99.568 19.4041L98.2292 20.8916V25.2055H94.8079V7.4542H98.2292V17.1728Z"
        fill="#0B0B2C"/>
      <path
        d="M119.67 24.3377V25.2055H115.951C115.703 24.8914 115.571 24.4452 115.554 23.8667C114.645 24.941 113.323 25.4782 111.587 25.4782C110.315 25.4782 109.29 25.0898 108.513 24.313C107.885 23.6849 107.571 22.8172 107.571 21.7098C107.571 20.0569 108.232 18.8834 109.554 18.1893C110.116 17.8918 111.232 17.5942 112.901 17.2967L115.579 16.8009L115.554 15.8588C115.538 14.9828 114.876 14.5448 113.571 14.5448C112.728 14.5448 112.133 14.677 111.786 14.9415C111.455 15.1894 111.249 15.6522 111.166 16.3298H107.893C108.092 13.3052 109.959 11.7929 113.496 11.7929C115.447 11.7929 116.852 12.1399 117.711 12.8341C118.488 13.4953 118.876 14.4952 118.876 15.834V23.1725C118.876 23.7675 119.141 24.1559 119.67 24.3377ZM115.579 18.8587L113.075 19.4289C112.364 19.5942 111.835 19.8338 111.488 20.1479C111.158 20.4619 110.992 20.8503 110.992 21.3131C110.992 22.2552 111.604 22.7263 112.827 22.7263C114.596 22.7263 115.505 21.7594 115.554 19.8256L115.579 18.8587Z"
        fill="#0B0B2C"/>
      <path
        d="M125.369 12.0656V13.9498C126.163 12.5118 127.378 11.7929 129.014 11.7929C129.791 11.7929 130.551 11.9829 131.295 12.3631C132.055 12.7432 132.658 13.2391 133.105 13.8506C134.047 15.1563 134.518 16.7844 134.518 18.7347C134.518 20.8338 133.931 22.5279 132.757 23.8171C131.749 24.9245 130.501 25.4782 129.014 25.4782C127.394 25.4782 126.179 24.8584 125.369 23.6188V30.511H121.948V12.0656H125.369ZM128.22 14.644C127.361 14.644 126.667 15.0159 126.138 15.7596C125.609 16.4869 125.345 17.4538 125.345 18.6603C125.345 19.8503 125.609 20.809 126.138 21.5362C126.667 22.2635 127.361 22.6271 128.22 22.6271C129.08 22.6271 129.774 22.2635 130.303 21.5362C130.832 20.809 131.096 19.8503 131.096 18.6603C131.096 17.4538 130.832 16.4869 130.303 15.7596C129.774 15.0159 129.08 14.644 128.22 14.644Z"
        fill="#0B0B2C"/>
      <path d="M140.406 7.4542V25.2055H136.985V7.4542H140.406Z" fill="#0B0B2C"/>
      <path
        d="M154.945 24.3377V25.2055H151.227C150.979 24.8914 150.846 24.4452 150.83 23.8667C149.921 24.941 148.599 25.4782 146.863 25.4782C145.59 25.4782 144.566 25.0898 143.789 24.313C143.161 23.6849 142.847 22.8172 142.847 21.7098C142.847 20.0569 143.508 18.8834 144.83 18.1893C145.392 17.8918 146.508 17.5942 148.177 17.2967L150.855 16.8009L150.83 15.8588C150.813 14.9828 150.152 14.5448 148.847 14.5448C148.004 14.5448 147.409 14.677 147.061 14.9415C146.731 15.1894 146.524 15.6522 146.442 16.3298H143.169C143.367 13.3052 145.235 11.7929 148.772 11.7929C150.722 11.7929 152.127 12.1399 152.987 12.8341C153.764 13.4953 154.152 14.4952 154.152 15.834V23.1725C154.152 23.7675 154.417 24.1559 154.945 24.3377ZM150.855 18.8587L148.351 19.4289C147.64 19.5942 147.111 19.8338 146.764 20.1479C146.433 20.4619 146.268 20.8503 146.268 21.3131C146.268 22.2552 146.88 22.7263 148.103 22.7263C149.871 22.7263 150.78 21.7594 150.83 19.8256L150.855 18.8587Z"
        fill="#0B0B2C"/>
      <path
        d="M157.249 12.0656H160.67V13.925C161.595 12.5036 162.901 11.7929 164.587 11.7929C166.091 11.7929 167.232 12.2391 168.008 13.1316C168.67 13.925 169 14.9993 169 16.3546V25.2055H165.604V17.0488C165.604 15.4786 164.868 14.6936 163.397 14.6936C162.571 14.6936 161.91 14.9332 161.414 15.4125C160.918 15.8753 160.67 16.4951 160.67 17.2719V25.2055H157.249V12.0656Z"
        fill="#0B0B2C"/>
    </svg>
  );
}

export default Logo;