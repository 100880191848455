import React from "react";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import WhiteLogo from "../../assets/logo/white-logo";
import ArrowLeftLong from "../../assets/icons/arrow-left-long";
import "./auth-layout.scss";

function AuthLayout() {
  return (
    <section className="auth-layout">
      <div className="auth-layout__image">
        <Link to="/">
          <WhiteLogo />
        </Link>
      </div>
      <div className="auth-layout__outlet">
        <div className="auth-layout__back">
          <Link to="/">
            <ArrowLeftLong />
            Back
          </Link>
        </div>
        <div className="auth-layout__component">
          <Outlet />
        </div>
      </div>
    </section>
  );
}

//
export default AuthLayout;
