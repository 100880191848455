import React from 'react';
import './footer.scss';
import {Link} from "react-router-dom";
import FooterLogo from "../../assets/logo/footer-logo";
import MailIcon from "../../assets/icons/mail-icon";
import PhoneIcon from "../../assets/icons/phone-icon";
import MapPinIcon from "../../assets/icons/map-pin-icon";
import FacebookIcon from "../../assets/icons/facebook-icon";
import TwitterIcon from "../../assets/icons/twitter-icon";
import InstagramIcon from "../../assets/icons/instagram-icon";
import TiktokIcon from "../../assets/icons/tiktok-icon";

function Footer() {
  function topHandler() {
    window.scrollTo(0, 0);
  }

  return (
    <footer
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="400"
      className="footer"
    >
      <div className="container">
        <div className="footer__wrapper">
          <div>
            <div
              data-aos="flip-left"
              data-aos-duration="1000"
              data-aos-delay="400"
              className="footer__logo"
              onClick={topHandler}
            >
              <Link to="/">
                <FooterLogo/>
              </Link>
            </div>
            {/*<div*/}
            {/*  data-aos="flip-up"*/}
            {/*  data-aos-duration="1000"*/}
            {/*  data-aos-delay="400"*/}
            {/*  className="footer__about">*/}
            {/*  <p>*/}
            {/*    It is a long established fact that a reader will be distracted by the readable content of a page when*/}
            {/*    looking at its layout.*/}
            {/*  </p>*/}
            {/*  <Link to="/about">Read more <ArrowRightShort/></Link>*/}
            {/*</div>*/}
            <div className="footer__social">
              <h5>Follow us:</h5>
              <div>

                <a
                  target="_blank"
                  rel="noopener"
                  data-aos="flip-left"
                  data-aos-duration="1100"
                  data-aos-delay="500"
                  href="https://www.facebook.com/codekaplan/"
                >
                  <FacebookIcon/>
                </a>
                <a
                  target="_blank"
                  rel="noopener"
                  data-aos="flip-left"
                  data-aos-duration="1200"
                  data-aos-delay="550"
                  href="https://twitter.com/codekaplan/"
                >
                  <TwitterIcon/>
                </a>
                <a
                  target="_blank"
                  rel="noopener"
                  data-aos="flip-left"
                  data-aos-duration="1300"
                  data-aos-delay="600"
                  href="https://www.instagram.com/codekaplan_/"
                >
                  <InstagramIcon/>
                </a>
                <a
                  target="_blank"
                  rel="noopener"
                  data-aos="flip-left"
                  data-aos-duration="1400"
                  data-aos-delay="650"
                  href="https://www.tiktok.com/@codekaplan"
                >
                  <TiktokIcon/>
                </a>
              </div>
            </div>
          </div>
          <ul className="footer__nav">
            <li className="footer__nav-title">Company</li>
            <li data-aos="flip-down"
                data-aos-duration="1000"
                data-aos-delay="400"><Link to="/about">About Us</Link></li>
            <li data-aos="flip-down"
                data-aos-duration="1000"
                data-aos-delay="450"><Link to="/courses">Courses</Link></li>
            {/* <li data-aos="flip-down"
                data-aos-duration="1000"
                data-aos-delay="500"><Link to="/practice">Practice</Link></li> */}
            <li data-aos="flip-down"
                data-aos-duration="1000"
                data-aos-delay="550"><Link to="/blog">Blog</Link></li>
            {/* <li data-aos="flip-down"
                data-aos-duration="1000"
                data-aos-delay="600"><Link to="/">Careers</Link></li> */}
          </ul>
          <ul className="footer__nav">
            <li className="footer__nav-title">Support</li>
            <li data-aos="flip-down"
                data-aos-duration="1000"
                data-aos-delay="500"><Link to="/terms">Terms of service</Link></li>
            <li data-aos="flip-down"
                data-aos-duration="1000"
                data-aos-delay="550"><Link to="/privacy">Privacy Policy</Link></li>
            <li data-aos="flip-down"
                data-aos-duration="1000"
                data-aos-delay="600"><Link to="/contact">Contact</Link></li>
          </ul>
          <ul className="footer__nav">
            <li className="footer__nav-title">Useful Links</li>
            <li data-aos="flip-down"
                data-aos-duration="1000"
                data-aos-delay="500"><Link to="/becomeateacher">Become A Teacher</Link></li>
          </ul>
        </div>
        <hr/>
        <div className="footer__copyright">
          Copyright © 2022. Codekaplan All rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;