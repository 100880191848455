import React from 'react';

function FacebookIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#122052" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1153 9.20276H13.8789V7.08017C13.8789 6.28303 14.4072 6.09719 14.7793 6.09719C15.1506 6.09719 17.0632 6.09719 17.0632 6.09719V2.59284L13.9178 2.58057C10.4262 2.58057 9.6316 5.19422 9.6316 6.86681V9.20276H7.6123V12.8138H9.6316C9.6316 17.448 9.6316 23.0318 9.6316 23.0318H13.8789C13.8789 23.0318 13.8789 17.393 13.8789 12.8138H16.7449L17.1153 9.20276Z"
      />
    </svg>

  );
}

export default FacebookIcon;