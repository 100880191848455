import React from 'react';

function ChevronUp() {
  return (
    <svg width="24" height="24" stroke="white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 15L12 9L6 15"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
}

export default ChevronUp;