import React from 'react';

function TiktokIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#122052" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2375 0.749023L12.4893 0.750115V16.2245C12.4893 17.5567 11.694 18.745 10.4633 19.2548C9.2325 19.7646 7.83126 19.4854 6.88929 18.5434C5.94731 17.6015 5.66814 16.1988 6.17793 14.968C6.68773 13.7372 7.87607 12.9438 9.20823 12.9438H10.4204V9.19518H9.20823C6.37052 9.19518 3.80048 10.913 2.71453 13.5347C1.62859 16.1564 2.23135 19.1882 4.23792 21.1948C6.24448 23.2014 9.2767 23.8041 11.8984 22.7182C14.5201 21.6322 16.2375 19.0622 16.2375 16.2245V7.64397C17.2581 8.25201 18.4636 8.69837 19.9462 8.69837H21.8196V4.94867H19.9462C17.8382 4.94867 17.2667 4.09127 16.7662 3.00316C16.2662 1.91609 16.2375 0.749023 16.2375 0.749023Z"
      />
    </svg>
  );
}

export default TiktokIcon;